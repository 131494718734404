const Anna = [
    './imgs/anne/anne1.jpg',
    './imgs/anne/anne2.png',
    './imgs/anne/anne3.jpg',
    './imgs/anne/anne4.png',
    './imgs/anne/anne5.png',
    './imgs/anne/anne6.jpg',
    './imgs/anne/anne7.jpg',
]

const Jeff = [
    './imgs/jeff/jeff1.png',
    './imgs/jeff/jeff2.png',
    './imgs/jeff/jeff3.png',
    './imgs/jeff/jeff4.png',
    './imgs/jeff/jeff5.jpg',
    './imgs/jeff/jeff6.jpg',
    './imgs/jeff/jeff7.jpg',
    './imgs/jeff/jeff8.jpg',
    './imgs/jeff/jeff9.jpg',
    './imgs/jeff/jeff10.jpg',

]

const All = [
    './imgs/anne/anne1.jpg',
    './imgs/anne/anne2.png',
    './imgs/jeff/jeff2.png',
    './imgs/jeff/jeff1.png',
    './imgs/anne/anne3.jpg',
    './imgs/anne/anne4.png',
    './imgs/jeff/jeff3.png',
    './imgs/anne/anne5.png',
    './imgs/anne/anne6.jpg',
    './imgs/jeff/jeff4.png',
    './imgs/jeff/jeff5.jpg',
    './imgs/anne/anne7.jpg',
    './imgs/jeff/jeff6.jpg',
    './imgs/jeff/jeff7.jpg',
    './imgs/jeff/jeff8.jpg',
    './imgs/jeff/jeff9.jpg',
    './imgs/jeff/jeff10.jpg',
]


export {Anna, Jeff, All}